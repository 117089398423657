var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"product-tether","bladeName":"product-tethers","bladesData":_vm.bladesData,"canAdd":"","canDelete":"","canSearchLocal":"","headers":[
        { text: 'Triggering Product', value: 'triggeringProduct.productName', title: 1, searchable: true },
        { text: 'Factor', value: 'factor' },
        { text: 'Quantity', value: 'quantity' },
        { text: 'Type', value: 'isManufactured', bool: true },
        { text: 'Tethered Product', value: 'tetheredProduct.productName' }],"navigation":"product-tethers","title":"Product Tethers","useServerPagination":""},scopedSlots:_vm._u([{key:"isManufactured",fn:function(ref){
        var item = ref.item;
return [(item.isManufactured)?_c('span',[_vm._v("On Manufacture")]):(item.isAcquired)?_c('span',[_vm._v("On Order Arrival")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }