<template>
    <BT-Blade-Items
        addBladeName="product-tether"
        bladeName="product-tethers"
        :bladesData="bladesData"
        canAdd
        canDelete
        canSearchLocal
        :headers="[
            { text: 'Triggering Product', value: 'triggeringProduct.productName', title: 1, searchable: true },
            { text: 'Factor', value: 'factor' },
            { text: 'Quantity', value: 'quantity' },
            { text: 'Type', value: 'isManufactured', bool: true },
            { text: 'Tethered Product', value: 'tetheredProduct.productName' }]"
        navigation="product-tethers"
        title="Product Tethers"
        useServerPagination>
        <template v-slot:isManufactured="{ item }">
            <span v-if="item.isManufactured">On Manufacture</span>
            <span v-else-if="item.isAcquired">On Order Arrival</span>
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Product-Tethers',
    props: {
        bladesData: null
    }
}
</script>